import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'oidc-react';
import * as Sentry from '@sentry/react';
import { AlertMessage } from '@eposnow/ui-core';
import { Box, Button, Card, CircularProgress, Typography, useTheme } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { UIContext } from '../../context/UIContext';
import { useSteps } from '../../components/StepController';
import IconAndText from './helpers/IconAndText';
import { CompanyInfo } from '../../types';
import { UserContext } from '../../context/UserContext';
import ErrorLoadingData from '../../components/ErrorLoadingData';
import { useUserCountry } from '../../helpers/useCountries';
import getBankAccountCurrencyImage from './helpers/helpers';

const BankAccount = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const stepContext = useSteps();
  const { userData } = useAuth();
  const { apiFetch, locale } = useContext(UserContext);
  const { colorMode } = useContext(UIContext);
  const { isLoading: isLoadingUserCountry, userCountry } = useUserCountry();
  const [errorMessageUpdateCompanyInfo, setErrorMessageUpdateCompanyInfo] = useState<string>('');

  document.title = t('app.pageTitle', { name: t('nav.bankAccount') });

  const {
    refetch: fetchCompanyInfo,
    isLoading,
    isError,
    error: companyInfoError,
  } = useQuery<CompanyInfo>(
    ['companyInfo'],
    () =>
      apiFetch(
        `${process.env.REACT_APP_API_ONBOARDING}v1/organisation/${userData.profile.sub}/info`,
        false
      ),
    {
      staleTime: 0,
      onSuccess(data: CompanyInfo) {
        if (data?.issueBankAccount !== null && data?.issueBankAccount !== undefined)
          stepContext.setIsMyBusinessAccount(data?.issueBankAccount);
      },
      onError(err: any) {
        if ((err as any)?.code === 401) return;
        Sentry.captureException(new Error('Error fetching company info'), {
          extra: { error: JSON.stringify(err) },
        });
        if (stepContext?.updateCurrentStep) stepContext.updateCurrentStep({ error: '' });
      },
    }
  );

  const unauthorized = (companyInfoError as any)?.code === 401;

  return isLoading || isLoadingUserCountry || unauthorized ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '96px',
      }}
    >
      <CircularProgress
        aria-label="Loading Data"
        aria-live="polite"
        aria-busy={isLoading || isLoadingUserCountry || unauthorized}
        data-testid="loading-icon"
        sx={{ color: 'text.secondary' }}
      />
    </Box>
  ) : (
    <>
      {errorMessageUpdateCompanyInfo && (
        <AlertMessage
          type="error"
          action={() => setErrorMessageUpdateCompanyInfo('')}
          iconAlignment="baseline"
          locale={locale}
          theme={theme}
        >
          {errorMessageUpdateCompanyInfo}
        </AlertMessage>
      )}
      {isError && <ErrorLoadingData action={fetchCompanyInfo} />}
      {!isError && (
        <Card
          variant="outlined"
          sx={{
            marginBottom: 2,
            padding: 0,
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            height: '100%',
          }}
        >
          <Box
            sx={{
              width: {
                sm: '55%',
                md: '65%',
              },
              padding: '2rem',
            }}
          >
            <Card
              variant="outlined"
              onClick={() => {
                stepContext.setIsMyBusinessAccount(true);
              }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '1rem',
                cursor: 'pointer',
                ...(stepContext.isMyBusinessAccount
                  ? {
                      border: `1px solid ${theme?.palette?.primary?.main}`,
                    }
                  : {
                      border: `1px solid ${
                        colorMode === 'light'
                          ? theme.palette.grey['12p']
                          : theme.palette.grey['23p']
                      }`,
                    }),
                ':hover': {
                  ...(!stepContext.isMyBusinessAccount &&
                    stepContext.isMyBusinessAccount !== null && {
                      border: `1px solid ${theme?.palette?.grey?.['50p']}`,
                      '.radio-selection': {
                        ...(!stepContext.isMyBusinessAccount &&
                          stepContext.isMyBusinessAccount !== null && {
                            borderTop: `1px solid ${theme?.palette?.grey?.['50p']}`,
                            backgroundColor: `${theme?.palette?.grey?.['4p']}`,
                          }),
                      },
                    }),
                },
              }}
            >
              <Box
                className="radio-selection"
                sx={{
                  padding: '1rem',
                  ...(stepContext.isMyBusinessAccount
                    ? {
                        borderBottom: `1px solid ${theme?.palette?.primary?.main}`,
                        backgroundColor: 'secondary.main',
                      }
                    : {
                        borderBottom: `1px solid ${
                          colorMode === 'light'
                            ? theme.palette.grey['12p']
                            : theme.palette.grey['23p']
                        }`,
                        backgroundColor: 'background.paper',
                      }),
                }}
              >
                <IconAndText
                  icon={
                    stepContext.isMyBusinessAccount ? (
                      <Box
                        sx={{
                          width: '40px',
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Button>
                          <RadioButtonCheckedIcon sx={{ color: 'primary.main' }} />
                        </Button>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: '40px',
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <Button>
                          <RadioButtonUncheckedIcon sx={{ color: 'text.secondary' }} />
                        </Button>
                      </Box>
                    )
                  }
                  textComponent={
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                      <Typography variant="h2" fontWeight={600}>
                        {t('screens.bankAccount.myBusinessAccount.title')}
                      </Typography>
                      <Typography variant="body2">
                        {t('screens.bankAccount.myBusinessAccount.description')}
                      </Typography>
                    </Box>
                  }
                />
              </Box>

              <Box
                sx={{
                  padding: '1rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  gap: '1rem',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                    paddingBottom: '1rem',
                    borderBottom: `1px ${
                      colorMode === 'light'
                        ? theme?.palette?.grey?.['12p']
                        : theme?.palette?.grey?.['23p']
                    } solid`,
                  }}
                >
                  <Typography variant="body1">
                    <strong>{t('screens.bankAccount.item1.header')} - </strong>
                    {t('screens.bankAccount.item1.description')}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                    paddingBottom: '1rem',
                    borderBottom: `1px ${
                      colorMode === 'light'
                        ? theme?.palette?.grey?.['12p']
                        : theme?.palette?.grey?.['23p']
                    } solid`,
                  }}
                >
                  <Typography variant="body1">
                    <strong>{t('screens.bankAccount.item2.header')} - </strong>
                    {t('screens.bankAccount.item2.description')}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                    paddingBottom: '1rem',
                    borderBottom: `1px ${
                      colorMode === 'light'
                        ? theme?.palette?.grey?.['12p']
                        : theme?.palette?.grey?.['23p']
                    } solid`,
                  }}
                >
                  <Typography variant="body1">
                    <strong>{t('screens.bankAccount.item3.header')} - </strong>
                    {t('screens.bankAccount.item3.description')}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                    paddingBottom: '1rem',
                    borderBottom: `1px ${
                      colorMode === 'light'
                        ? theme?.palette?.grey?.['12p']
                        : theme?.palette?.grey?.['23p']
                    } solid`,
                  }}
                >
                  <Typography variant="body1">
                    <strong>{t('screens.bankAccount.item4.header')} - </strong>
                    {t('screens.bankAccount.item4.description')}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.5rem',
                  }}
                >
                  <Typography variant="body1">
                    <strong>{t('screens.bankAccount.item5.header')} - </strong>
                    {t('screens.bankAccount.item5.description')}
                  </Typography>
                </Box>
              </Box>
            </Card>

            <Card
              variant="outlined"
              onClick={() => {
                stepContext.setIsMyBusinessAccount(false);
              }}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '1rem',
                cursor: 'pointer',
                ...(!stepContext.isMyBusinessAccount && stepContext.isMyBusinessAccount !== null
                  ? {
                      border: `1px solid ${theme?.palette?.primary?.main}`,
                      backgroundColor: 'secondary.main',
                    }
                  : {
                      border: `1px solid ${
                        colorMode === 'light'
                          ? theme.palette.grey['12p']
                          : theme.palette.grey['23p']
                      }`,
                      backgroundColor: 'background.paper',
                    }),
                ':hover': {
                  ...(stepContext.isMyBusinessAccount && {
                    border: `1px solid ${theme?.palette?.grey?.['50p']}`,
                    backgroundColor: `${theme?.palette?.grey?.['4p']}`,
                  }),
                },
              }}
            >
              <IconAndText
                icon={
                  !stepContext.isMyBusinessAccount && stepContext.isMyBusinessAccount !== null ? (
                    <Box
                      sx={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button>
                        <RadioButtonCheckedIcon sx={{ color: 'primary.main' }} />
                      </Button>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Button>
                        <RadioButtonUncheckedIcon sx={{ color: 'text.secondary' }} />
                      </Button>
                    </Box>
                  )
                }
                textComponent={
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                    <Typography variant="h2" fontWeight={600}>
                      {t('screens.bankAccount.existingAccount.title')}
                    </Typography>
                    <Typography variant="body2">
                      {t('screens.bankAccount.existingAccount.description')}
                    </Typography>
                  </Box>
                }
              />
            </Card>
          </Box>

          <Box
            sx={{
              width: { sm: '45%', md: '35%' },
              padding: '1rem',
              backgroundColor: 'secondary.main',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '2rem',
            }}
          >
            <img
              src={getBankAccountCurrencyImage(userCountry)}
              alt=""
              style={{
                maxWidth: '90%',
              }}
            />
          </Box>
        </Card>
      )}
    </>
  );
};

export default BankAccount;
