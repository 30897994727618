import BankAccountImageGBP from '../../../img/bankAccountImageGBP.png';
import BankAccountImageUSD from '../../../img/bankAccountImageUSD.png';

const getBankAccountCurrencyImage = (userCountry) => {
  switch (userCountry) {
    case 'GB':
      return BankAccountImageGBP;
    default:
      return BankAccountImageUSD;
  }
};

export default getBankAccountCurrencyImage;
